import http from '@/utils/http'
const url = 'cms/user'

export const getSystemUsers = data => {
  return http.request(url, 'get', {}, data)
}

export const getSystemUser = id => {
  return http.request(url + '/' + id, 'get')
}

export const editSystemUser = data => {
  return http.request(url, 'post', data)
}

export const login = data => {
  return http.request(url + '/login', 'post', data)
}

export const modifyPassword = data => {
  return http.request(url + '/modifyPassword', 'post', data)
}