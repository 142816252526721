import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import './assets/styles/global.css'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import EleForm from 'vue-ele-form'
import EleTable from 'vue-ele-table'
import EleFormTableEditor from 'vue-ele-form-table-editor'
import EleFormImageUploader from 'vue-ele-form-image-uploader'
import EleSelect from '@/components/EleSelect.vue'
import EleImageSelect from '@/components/ImageSelect.vue'
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

import Api from './api'
// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

Vue.use(VMdPreview);
Vue.config.productionTip = false
Vue.prototype.$api = Api
Vue.use(Element)
Vue.use(EleForm, {
  number: {
    min: 0
  },
  upload: {
    fileSize: 10
  },
  'image-uploader': {
    action: process.env.VUE_APP_UPLOAD_URL
  }
})
Vue.use(EleTable, {
  defaultSize: 10
})

Vue.component('table-editor', EleFormTableEditor)
Vue.component('ele-select', EleSelect)
Vue.component(EleImageSelect.name, EleImageSelect)
Vue.component('image-uploader', EleFormImageUploader)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
