export const systemRoutes = [
  {
    path: '/cms/user',
    name: 'cmsUser',
    meta: {
      title: '用户管理',
      type: 'cms'
    },
    component: '/system/SystemUser.vue'
  },
  {
    path: '/cms/operatingLog',
    name: 'operatingLog',
    meta: {
      title: '操作日志',
      type: 'cms'
    },
    component: '/system/OperatingLog.vue'
  },
  {
    path: '/app/dashboard',
    name: 'appDashboard',
    meta: {
      title: '系统信息',
      type: 'app'
    },
    component: '/app/AppDashboard.vue'
  },
  {
    path: '/app/user',
    name: 'appUser',
    meta: {
      title: '用户管理',
      type: 'app'
    },
    component: '/app/AppUser.vue'
  },
  {
    path: '/app/banner/group',
    name: 'appBannerGroup',
    meta: {
      title: '横幅分组管理',
      type: 'app'
    },
    component: '/app/AppBannerGroup.vue'
  },
  {
    path: '/app/banner',
    name: 'appBanner',
    meta: {
      title: '横幅管理',
      type: 'app'
    },
    component: '/app/AppBanner.vue'
  },
  {
    path: '/app/classify',
    name: 'appClassify',
    meta: {
      title: '分类管理',
      type: 'app'
    },
    component: '/app/AppClassify.vue'
  },
  {
    path: '/app/tag',
    name: 'appTag',
    meta: {
      title: '标签管理',
      type: 'app'
    },
    component: '/app/AppTag.vue'
  },
  {
    path: '/app/image',
    name: 'appImage',
    meta: {
      title: '图片管理',
      type: 'app'
    },
    component: '/app/AppImage.vue'
  }
]