import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
const state = {
  userData: {},
  token: '',
  activePath: ''
}
const mutations = {
  setUserData(state, data) {
    state.userData = data
  },
  setToken(state, data) {
    state.token = data
  },
  setActivePath(state, path) {
    state.activePath = path
  },
  logout(state) {
    state.token = ''
  }
}

const getters = {}
const actions = {}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: 'TANK_MANAGER',
      render(state) {
        return { ...state }
      }
    })
  ]
})
