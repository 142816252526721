import http from '@/utils/http'
const url = 'app/tag'

export const getAppTags = data => {
  return http.request(url, 'get', {}, data)
}

export const editAppTag = data => {
  return http.request(url, 'post', data)
}

export const removeAppTag = (id, restore) => {
  return http.request(url + '/' + id + '/' + restore, 'delete')
}

export const getAppTagOptions = () => {
  return http.request(url + '/options', 'get')
}