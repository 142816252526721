import http from '@/utils/http'
const url = 'app/dashboard'

export const getAppImageInfo = () => {
  return http.request(url + '/appImage', 'get')
}

export const getAppUserFeedBackInfo = (params) => {
  return http.request(url + '/feedback', 'get', {}, params)
}

export const getChangeLog = () => {
  return http.request(url + '/changeLog', 'get')
}