import http from '@/utils/http'
const url = 'app/banner'

export const getAppBanners = data => {
  return http.request(url, 'get', {}, data)
}

export const editAppBanner = data => {
  return http.request(url, 'post', data)
}

export const removeAppBanner = (id, restore) => {
  return http.request(url + '/' + id + '/' + restore, 'delete')
}

export const getAppBannerList = () => {
  return http.request(url + '/options', 'get')
}