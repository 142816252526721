import http from '@/utils/http'
const url = '/app/banner/group'

export const getAppBannerGroups = data => {
  return http.request(url, 'get', {}, data)
}

export const editAppBannerGroup = data => {
  return http.request(url, 'post', data)
}

export const addBanners = data => {
  return http.request(url + '/relation', 'post', data)
}