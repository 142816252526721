<template>
  <el-select
    :class="desc.class"
    :style="desc.style"
    class="ele-form-full-line"
    v-bind="attrs"
    v-model="newValue"
    :loading="loading"
    :remote-method="remoteMethod?changeOptions:null"
    v-on="onEvents"
    v-image-select-loadmore="loadMore"
  >
    <template v-for="(render, key) of slots" v-slot:[key]>
      <extend-slot
        :data="{
          options: desc.options
        }"
        :key="key"
        :render="render"
      />
    </template>
    <el-option
      :key="option.id"
      :label="option.text"
      :value="option.value"
      v-bind="option.attrs"
      v-for="option in appImageOptions"
    >
      <img class="avatar" :src="option.url" style="height: 72px;">
      <span class="showText">{{ option.text }}</span>
    </el-option>
  </el-select>
</template>

<script>
import formMixin from 'vue-ele-form/lib/mixins/formMixin'

export default {
  name: 'image-select',
  mixins: [formMixin],
  inject: ['EleForm'],
  directives: {
    'image-select-loadmore': {
      bind(el, binding) {
        const selectWrapDom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
        selectWrapDom.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  props: {
    field: String
  },
  data() {
    return {
      loading: false,
      mockRule: 'radio',
      type: ['Boolean', 'Number', 'String', 'Array'],
      appImageOptions: [],
      params: {
        page: 1,
        size: 10
      }
    }
  },
  computed: {
    remoteMethod() {
      return this.attrs['remote-method'] || this.attrs.remoteMethod
    },
    defaultAttrs() {
      return {
        placeholder: this.t('ele-form.select') + this.desc._label
      }
    }
  },
  methods: {
    changeOptions(q) {
      if (this.remoteMethod) {
        this.loading = true
        this.remoteMethod(q, options => {
          this.loading = false
          this.$set(this.EleForm.formDescData[this.field], '_options', options)
          this.EleForm.formDescData[this.field].isRestValByOptions = false
          this.EleForm.changeOptions(options, this.field)
        })
      }
    },
    loadMore() {
      this.params.page++
      this.getAppImageOptions(this.params)
    }, 
    async getAppImageOptions(params) {
      const { data: res } = await this.$api.getAppImageOptions(params)
      if (res.code > 0){
        return this.$message.error(res.message)
      }
      this.appImageOptions = [...this.appImageOptions, ...res.data]
    }
  },
  mounted() {
    this.getAppImageOptions(this.params)
    if (this.remoteMethod && this.newValue) {
      this.changeOptions(this.newValue)
    }
  }
}
</script>

<style scoped>
.el-select-dropdown__item {
  height: 72px !important;
  line-height: 72px !important;
}

.showText {
  display: inline-block;
  vertical-align: top;
  padding-left: 15px;
}
</style>