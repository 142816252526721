import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { systemRoutes } from '@/assets/js/routes'

Vue.use(VueRouter)

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

const staticRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/Home.vue'),
    redirect: '/app/dashboard',
    children: []
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
    meta: {
      title: '用户登录'
    }
  }
]

function initRoutes() {
  const routes = [...staticRoutes]
  for (const item of systemRoutes) {
    const route = {
      path: item.path,
      name: item.name,
      meta: item.meta,
      component: () => import(`@/pages${item.component}`)
    }
    routes[0].children.push(route)
  }

  return routes
}

const routes = initRoutes()

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title === undefined ? 'TankManager' : to.meta.title
  const token = store.state.token
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  } else if (!token && to.name === 'login') {
    next()
  } else {
    next()
  }
})

export default router
