import http from '@/utils/http'
const url = 'app/image'

export const getAppImageOptions = (params) => {
  return http.request(url + '/options', 'get', {}, params)
}

export const getAppImages = params => {
  return http.request(url, 'get', {}, params)
}

export const editAppImage = data => {
  return http.request(url, 'post', data)
}

export const deleteAppImage = id => {
  return http.request(url + '/' + id, 'delete')
}