import axios from 'axios'
import { Message } from 'element-ui'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import router from '../router'
import store from '../store'
axios.defaults.withCredentials = true

class Axios {
  constructor() {
    this.baseURL = process.env.VUE_APP_API_URL
    this.timeout = 100000
    this.withCredentials = true
  }

  setInterceptors = (instance) => {
    instance.interceptors.request.use((config) => {
      nprogress.start()
      const token = store.state.token
      config.headers.Authorization = 'Bearer ' + token
      return config
    }, err => Promise.reject(err))

    instance.interceptors.response.use(res => {
      nprogress.done()
      return res
    }, err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            Message.error('账号已过期, 请重新登陆')
            router.replace({
              path: '/login'
            })
            break
        }
        return Promise.reject(err.response)
      } else {
        router.replace({
          path: '/login'
        })
      }
      return Promise.reject(err)
    })
  }

  /**
   * axios 请求
   * @param {*} url 接口地址
   * @param {*} method 方法类型 get post put delete
   * @param {*} data post 数据
   * @param {*} params get 数据
   * @param {*} options 
   */
  request(url, method, data = {}, params = {}, options) {
    const instance = axios.create()

    const config = {
      url,
      method,
      params,
      data,
      ...options,
      baseURL: this.baseURL,
      timeout: this.timeout,
      withCredentials: this.withCredentials
    }
    this.setInterceptors(instance)
    return instance(config)
  }
}

export default new Axios()
