import http from '@/utils/http'
const url = 'app/classify'

export const getAppClassifies = data => {
  return http.request(url, 'get', {}, data)
}

export const editAppClassify = data => {
  return http.request(url, 'post', data)
}

export const removeAppClassify = (id, restore) => {
  return http.request(url + '/' + id + '/' + restore, 'delete')
}

export const getAppClassifyOptions = () => {
  return http.request(url + '/options', 'get')
}